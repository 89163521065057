<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="boxdatas" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['inspectionno']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入验货编号模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.inspectionno" @click="openDetail(slotProps.data,false)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable"
                :childNewDataConfig="childNewDataConfig" :childTableFooter="childTableFooter"
                :pageJsonConfig="pageJsonConfig" :mainelementCss="mainelementCss"
                :childDelDataConfig="childDelDataConfig" :CellEditCompleteMethod="cellEditComplete"
                :loadDataAfterMethod="loadDataAfter" :childExpandePageConfig="childExpandeConfig"
                :dropOtherConfig="dropOtherConfig" :openChildDialogMethod="openinspectionfileDialog"
                :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button :label="editHistoryButTxt" icon="pi pi-tags" class="ml-auto" @click="showEditHistory" />
                <Button label="关闭" icon="pi pi-power-off" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="验货文件" @hide="closeInspectionFile" v-model:visible="displayInspectionFile" :style="{width: '40vw'}"
        :modal="true">
        <Toolbar v-if="!readOnly && !elementAuthority('childs.inspectionfile')" class="mb-2">
            <template #start>
                <Button label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                    @click="createInspectionFiles2()" />
                <Button label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                    @click="removeInspectionFiles2()" />
            </template>
        </Toolbar>
        <DataTable :value="inspectionfiledata['inspectionfile']" v-model:selection="selectedInspectionFileRow"
            dataKey="id" :metaKeySelection="false" scrollHeight="300px" selectionMode="multiple" scrollDirection="both"
            editMode="cell" @cell-edit-init="inspectionfileonRowInit"
            @row-edit-save="inspectionfileonRowEditSave2($event,slotProps.data.rows)"
            @cell-edit-complete="inspectionfilecellEditComplete2($event)" class="editable-cells-table"
            responsiveLayout="scroll">
            <Column v-if="!readOnly && !elementAuthority('childs.inspectionfile')" selectionMode="multiple"
                headerStyle="width: 3em">
            </Column>
            <Column field="filename" header="文件">
                <template v-if="!readOnly && !elementAuthority('childs.inspectionfile')" #editor="slotProps">
                    <UploadInput v-model:filePatch="slotProps.data['filepatch']"
                        v-model:fileName="slotProps.data['filename']" fileType="" />
                </template>
                <template #body="slotProps">
                    <a :href="getFilePatch(slotProps.data.filepatch)" target="_blank">{{slotProps.data.filename}}</a>
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="确认" icon="pi pi-power-off" class="ml-auto" @click="closeInspectionFile" />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import UploadInput from '../../../components/UploadInput.vue';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '786';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                editHistoryButTxt: '显示修改痕迹',
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                selectedInspectionFileRow: [],
                inspectionfiledata: {
                    "inspectionfile": []
                },
                inspectionfiledeldata: [],
                displayInspectionFile: false,
                position: 'bottomleft',
                currentState: '流程信息',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                currentRow: {
                    bindpage: '',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                pageJsonConfig: {},
                currencyDict: [],
                flowhistoryJson: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '验货待办任务',
                selectedKey: {
                    786: true
                },
                expandedKey: null,
                childExpandeConfig: {},
                childDelDataConfig: {},
                boxdatas: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '验货操作',
                    icon: 'pi pi-fw pi-users',
                    items: []
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                configData: {},
                config2Data: {},
                childNewDataConfig: {},
                childTableFooter: {},
                mainelementCss: {},
                dropOtherConfig: {},
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getinspectionmain(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.configData = data;
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig'
                    ];
                    this.initFilters1();
                    this.loadMenuTree();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            getNewId() {
                return this.$refs.universalpage.getNewId();
            },
            elementAuthority(v_name) {
                var authorityobj = this.$refs.universalpage.getelementAuthority();
                console.log('elementAuthority....');
                console.log(authorityobj);
                return authorityobj[v_name];
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('791', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '验货待办任务', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'inspectionbox',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'inspectionno',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{inspectionmainlist(where:" + JSON.stringify(listwhere) +
                    "){id inspectionno inspectiontime approvestatus approvestatusname flowid workflowid boxid stateid currentstatename creater createtime}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.boxdatas = jsonData.data.inspectionmainlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            openDetail(v_data) {
                this.editHistoryButTxt = '显示修改痕迹';
                this.currentState = '流程信息-当前环节:' + v_data.currentstatename;
                this.recordsubject = v_data['inspectionno'];
                this.currentRow = {
                    bindpage: 'inspectionmain',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'Inspection',
                    boxid: v_data.boxid,
                    stateid: v_data.stateid,
                    flowid: v_data.flowid,
                };
                this.displayMaximizable = true;
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
                this.editHistoryButTxt = '显示修改痕迹';
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            expandertoggle(event) {
                this.$refs.op.toggle(event);
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            },
            openinspectionfileDialog(v_childdata, v_parentdata) {
                console.log('openinspectionfileDialog...');
                console.log(v_childdata);
                console.log(v_parentdata);
                this.inspectionfiledata = v_parentdata;
                this.inspectionfiledeldata = [];
                this.selectedInspectionFileRow = null;
                for (var i = this.inspectionfiledata['inspectionfile'].length - 1; i >= 0; i--) {
                    if (this.inspectionfiledata['inspectionfile'][i]['isdel'] == '1') {
                        this.inspectionfiledeldata.push(this.inspectionfiledata['inspectionfile'][i]);
                        this.inspectionfiledata['inspectionfile'].splice(i, 1);
                    }
                }
                this.displayInspectionFile = true;
            },
            createInspectionFiles2() {
                this.inspectionfiledata['inspectionfile'].unshift({
                    id: this.getNewId(),
                    filename: '',
                    filepatch: '',
                    parentid: '',
                    purchaseid: this.inspectionfiledata['purchaseid']
                });
                this.selectedInspectionFileRow = null;
            },
            removeInspectionFiles2() {
                setTimeout(() => {
                    console.log(this.selectedInspectionFileRow);
                    if (!this.selectedInspectionFileRow || this.selectedInspectionFileRow.length == 0) {
                        MessageTip.warnmsg('请选择要移除的验货文件');
                        return;
                    }
                    var datasource = this.inspectionfiledata['inspectionfile'];
                    for (var i2 = 0; i2 < this.selectedInspectionFileRow.length; i2++) {
                        for (var i = 0; i < datasource.length; i++) {
                            if (datasource[i]['id'] == this.selectedInspectionFileRow[i2]['id']) {
                                if (parseInt(this.selectedInspectionFileRow[i2]['id']) < 0) {
                                    datasource.splice(i, 1);
                                } else {
                                    datasource[i]["isdel"] = '1';
                                    this.inspectionfiledeldata.push(datasource[i]);
                                    datasource.splice(i, 1);
                                }
                                break;
                            }
                        }
                    }
                    this.inspectionfiledata['inspectionfile'] = datasource;
                    this.selectedInspectionFileRow = null;
                }, 50);
            },
            inspectionfileonRowEditSave2(event, v_data) {
                console.log("inspectionfileonRowEditSave2:");
                console.log(event);
                console.log(v_data);
                let {
                    newData,
                    index
                } = event;
                v_data[index] = newData;
            },
            inspectionfileonRowInit(event, v_data) {
                console.log('inspectionfileonRowInit....');
                console.log(event);
                console.log(v_data);
            },
            inspectionfilecellEditComplete2(event) {
                console.log("inspectionfilecellEditComplete2:");
                console.log(event);
                for (var pro in event.newData) {
                    event.data[pro] = event.newData[pro];
                }
            },
            closeInspectionFile() {
                for (var i = 0; i < this.inspectionfiledeldata.length; i++) {
                    this.inspectionfiledata['inspectionfile'].push(this.inspectionfiledeldata[i]);
                }
                this.inspectionfiledata = {};
                this.inspectionfiledeldata = [];
                this.displayInspectionFile = false;
            },
            getFilePatch(v_patch) {
                return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
            },
            showEditHistory() {
                if (this.editHistoryButTxt == '隐藏修改痕迹') {
                    this.$refs.universalpage.clearEditHistory();
                    this.editHistoryButTxt = '显示修改痕迹';
                    return;
                }
                this.$refs.universalpage.showEditHistory(this.seteditHistoryButTxt);
            },
            seteditHistoryButTxt() {
                this.editHistoryButTxt = '隐藏修改痕迹';
            }
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            UploadInput,
            'AppFooter': AppFooter,
        }
    }
</script>